/* Styles généraux */
@font-face {
    font-family: 'Chandstate';
    src: url('charte/Chandstate.otf') format('opentype');
}
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.header-title{
    color: #ffffff;
    font-size:large;
    font-family: Chandstate, sans-serif;
}

.feature-pinkname {
    color: #ff007f;
    font-weight: bold;
}

.feature-pinkname-title {
    color: #ff007f;
    font-size:x-large;
    font-family: Chandstate, sans-serif;
    font-weight: bold;
}

.homepage-features-videos {
    align-content: center;
    justify-content: center;
    text-align: center;
}

.formheader {
    text-align: center;
}

.carousel-title {
    color: #ff007f;
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.feature-card {
    max-height: 400px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
    display: block;
    text-align: center;
    padding: 20px;
    z-index: 1;
    margin-bottom: 10px;
    
}

.feature-card p {
    text-align: justify;
    margin-top: 10px;
    
}

.feature-card-center {
    align-items: center;
    text-align: center;
}

.feature-card img {
    margin: 10px;
    max-height: 400px;
    align-content: center;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
}

/* Conteneur général */
.homepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    background-color: #252122;
    color: #fff;
    padding-top: 1px; /* Moins de padding pour les petits écrans */
    
    gap: 20px; /* Ajoutez un écart entre les sections */
}

/* Header */
.homepage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #252122;
    color: #fff;
    position: relative;
    position: fixed; /* Rend le header fixe */
    top: 0; /* Le place en haut */
    left: 50%;
    transform: translateX(-50%);
    max-width: 1168px;
    width: 100%; /* Assure que le header occupe toute la largeur */
    z-index: 1000; /* Le place au-dessus des autres éléments */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optionnel, ajoute une ombre légère */
}

.logo-toggle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homepage-logo {
    width: 150px;
    height: auto;
    margin-bottom: 5px;
}

.menu-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
    position: relative; /* Nécessaire pour que le menu-content soit positionné par rapport à ce conteneur */
    z-index: 10; /* Assure l'affichage au-dessus des autres éléments */
}

.menu-toggle {
    font-size: 1.5em;
    cursor: pointer;
    color: #ff007f;
    padding: 5px;
    background-color: transparent;
    border: none;
}

.menu-content {
    display: flex;
    flex-direction: column;
    font-family: Chandstate, sans-serif;
    background-color: #252122;
    color: #fff;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 10;
    margin-top: 5px;
}

/* Menu items */
.menu-item {
    padding: 10px;
    font-size: 1em;
    border-bottom: 1px solid #444;
    cursor: pointer;
    color: #fff;
}

.menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.highlight-text {
    color: inherit;
}

.chevron-icon {
    color: #ff007f;
}

.sub-menu {
    padding-left: 20px;
    margin-top: 5px;
    background-color: #353535;
    border-left: 2px solid #ff007f;
}
.social-button {
    color: #ff007f;
    transition: color 0.3s ease;
}
.menu-item:hover .highlight-text {
    color: #ff007f;
}

/* Définir les couleurs par défaut */
.menu-item, .sub-menu .menu-item {
    color: #fff;
    background-color: transparent;
}

/* Applique le rose uniquement à l'élément actuel sous le curseur, quel que soit le niveau */
.menu-item:hover > .menu-title > .highlight-text,
.sub-menu .menu-item:hover > .menu-title > .highlight-text {
    color: #ff007f; /* Applique la couleur rose seulement au texte sous le curseur */
}

/* Garde les éléments parents et frères en blanc lors du survol */
.menu-item:hover > .sub-menu .menu-item .highlight-text,
.sub-menu .menu-item:hover .highlight-text,
.menu-item .sub-menu .menu-item:hover .highlight-text {
    color: #fff; /* Assure que seuls les éléments sous le curseur soient colorés */
}

/* Suppression de l'effet de propagation de fond pour un effet plus subtil */
.menu-item:hover,
.sub-menu .menu-item:hover {
    background-color: transparent; /* Maintient la transparence du fond au survol */
}

/* Styles généraux du menu */
.mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: #252122;
    color: #fff;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 60px;
    z-index: 1000;
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.3);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.mobile-menu.open {
    transform: translateX(0);
}



.mobile-menu .k-menu-item {
    padding: 15px 20px;
    border-bottom: 1px solid #444;
    color: #fff;
    text-align: left;
    cursor: pointer;
    font-size: 1em;
}

.mobile-menu .k-menu-item:hover {
    background-color: #ff007f;
}

.mobile-menu .k-menu-group {
    background-color: #353535;
    padding-left: 20px;
    display: none;
    border-top: 1px solid #444;
}

/* Styles spécifiques pour ouvrir les sous-menus et sous-sous-menus */
.mobile-menu .k-menu-item.k-state-focused > .k-menu-group,
.mobile-menu .k-menu-item.k-state-selected > .k-menu-group {
    display: block; /* Affiche le sous-menu à l'état actif */
}

.mobile-menu .k-menu-group .k-menu-item {
    padding: 10px 20px;
    border-top: 1px solid #444;
    cursor: pointer;
}

.mobile-menu .k-menu-group .k-menu-item:hover {
    background-color: #ff007f;
}

/* Suppression des grosses flèches */
.mobile-menu .k-menu-expand-arrow {
    display: none; /* Supprime les flèches d'extension des sous-menus */
}

.homepage-menu {
    display: none;
}

/* Cacher l'icône sur les écrans plus grands */
@media (min-width:768px) {
    .mobile-menu {
        display: block;
    }
    .homepage-header {
        padding:1em;
        width: 100%; /* Laisse le header prendre toute la largeur sur mobile */
    }
}

/* Positionnement du sous-sous-menu à droite du sous-menu */
.mobile-menu .k-menu-group .k-menu-group {
    position: absolute;
    top: 0;
    left: 100%; /* Décalage à droite du sous-menu parent */
    margin-left: 5px;
    background-color: #444;
    padding: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    display: none;
}

/* Affichage du sous-sous-menu lors du survol */
.mobile-menu .k-menu-item.k-state-focused > .k-menu-group,
.mobile-menu .k-menu-item.k-state-selected > .k-menu-group,
.mobile-menu .k-menu-group .k-menu-item.k-state-focused > .k-menu-group,
.mobile-menu .k-menu-group .k-menu-item.k-state-selected > .k-menu-group {
    display: block;
}

/* Social media */
.social-media {
    display: flex;
    align-items: center;
    gap: 1em;
}

.social-media p {
    margin: 0;
    font-size: 1.1em;
    background-color: #252122;
    color: #fff;
}

.social-button {
    color: #ff007f;
    transition: color 0.3s ease;
    padding: 10px;
}

.social-button:hover {
    color: #fff;;
}

.homepage-logo {
    width: 150px;
    height: auto;
}

/* Bannière */
.homepage-banner {
    margin-bottom: 20px;
    margin-top: 225px;
}

.slider {
    width: 100%;
    overflow: hidden;
    height: auto;
}

.slides {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.banner-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

/* Call to action */
.homepage-cta {
    position: relative;
    margin: 2em 0;
    z-index: 1;
    
}

.cta-button,
.cta-button-secondary {
    background-color: #ff007f;
    color: #fff;
    padding: 0.75em 2em;
    margin: 0.5em;
    border: none;
    cursor: pointer;
    
}

.cta-button:hover,
.cta-button-secondary:hover {
    background-color: #252122;
}

/* Chatbot styles */
.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chat-icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.chat-icon:hover {
    transform: scale(1.1);
}

.chat-window {
    position: absolute;
    bottom: 60px;
    right: 0;
    background-color: #fff;
    padding: 15px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-window h4 {
    margin-top: 0;
    font-size: 18px;
    color: #333;
}

.chat-window input,
.chat-window textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.chat-window button {
    background-color: #ff007f;
    color: #fff;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.chat-window button:hover {
    background-color: #e6006b;
}
