.carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel img{
    margin: 10px;
    max-height: 600px;
    align-content: center;
    justify-content: center;
    text-align: center;
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.carousel-image-container {    
    max-width: 100%;
    height: auto;
}

.carousel-button {
    background: none;
    border: none;
    font-size: 2em;
    
    margin-left: 15px;
    color: #ff007f;
    cursor: pointer;
}

.carousel-dots {
    text-align: center;
    margin-top: 10px;
}

.carousel-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
}

.carousel-dot.active {
    background-color: #ff007f;
}
