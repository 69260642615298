/* Styles généraux */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.homepage-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.homepage-header {
  text-align: center;
  padding: 20px 0;
}

.homepage-logo {
  max-width: 150px;
  height: auto;
}

.homepage-menu {
  margin-bottom: 20px;
}

.menu-horizontal {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.menu-horizontal a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

.dropdown-container {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
}

.dropdown-container:hover .dropdown {
  display: block;
}

.dropdown a {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
}

/* Bannière d'images */
.homepage-banner {
  position: relative;
  overflow: hidden; /* Assure que les images ne débordent pas */
}

.banner-image {
  width: 100%;
  height: 300px; /* Hauteur fixe pour la bannière */
  object-fit: cover; /* Les images remplissent l'espace tout en gardant les proportions */
  object-position: center; /* Centrer les images pour éviter qu'elles soient mal positionnées */
}

.tab-content {
  padding: 20px;
}

.homepage-cta {
  text-align: center;
  padding: 20px;
}

.cta-button,
.cta-button-secondary {
  margin: 10px;
}

/* Responsive design pour les petits écrans */
@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }

  .homepage-logo {
    max-width: 100px;
  }

  .menu-horizontal {
    flex-direction: column;
    align-items: center;
  }

  .menu-horizontal a {
    font-size: 16px;
  }

  .homepage-banner {
    margin-top: 20px;
  }

  .cta-button,
  .cta-button-secondary {
    display: block;
    width: 90%;
    margin: 10px auto;
  }

  .tab-content {
    padding: 10px;
  }

  .banner-image {
    height: 200px; /* Ajuste la hauteur des images sur les petits écrans */
  }
}
