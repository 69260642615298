/* PaymentModal.css */
.payment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal {
  background: #252122; /* Utilisation de la même couleur que le fond de votre application */
  color: #fff; /* Pour le texte blanc */
  padding: 40px; /* Plus d'espace pour un style cohérent */
  border-radius: 8px;
  width: 90%;
  max-width: 800px; /* Ajusté pour correspondre à un formulaire plus large */
  max-height: 90vh; /* Limite la hauteur pour s'assurer qu'elle s'adapte à l'écran */
  overflow-y: auto; /* Permet de faire défiler si le contenu dépasse */
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #fff; /* Cohérence avec le reste du texte */
  cursor: pointer;
}

@media (max-width: 600px) {
  .payment-modal {
      width: 95%;
      max-width: none; /* Pas de limite sur les petits écrans */
      padding: 20px; /* Réduction du padding pour les petits écrans */
  }
}
